import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from './logo.png'; // Update this path to your actual logo image
import styles from './banner.module.css'; // Ensure this path is correct

function Banner() {
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);

    return (
        <header className={styles.header}>
            <img src={logo} alt="Logo" className={styles.logo} />
            <nav className={styles.nav}>
                <ul className={styles.navList}>
                    <li className={styles.navItem}><Link to="/" className={styles.psLink}>PUCKSAVVY</Link></li>
					<li className={styles.navItem}><Link to="https://buy.stripe.com/cN2bK468T9Ym5yw4gh" className={styles.navLink}>BECOME MEMBER</Link></li>
                </ul>
            </nav>
        </header>
    );
}

export default Banner;
