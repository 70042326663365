import React from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import Standings from './standings';
import Teams from './teams';
import Players from './players';
import Commissioner from './commissioner';
import logo from './logo.png';

function FIHL() {
  return (
    <div>
      <div style={{ backgroundColor: '#0c487f', padding: '1em', textAlign: 'center' }}>
        <img src={logo} alt="Flat Ice Hockey League" style={{ width: '50px', height: 'auto', borderRadius: '5px' }} />
        <h1>Flat Ice Hockey League</h1>
      </div>

      <nav style={{ backgroundColor: '#eee', padding: '0.5em', textAlign: 'center' }}>
        <Link to="standings" style={{ margin: '0 1em' }}>Standings</Link>
        <Link to="teams" style={{ margin: '0 1em' }}>Teams</Link>
        <Link to="players" style={{ margin: '0 1em' }}>Players</Link>
        <Link to="commissioner" style={{ margin: '0 1em' }}>Commissioner Portal</Link>
      </nav>

      <Routes>
        <Route path="standings" element={<Standings />} />
        <Route path="teams" element={<Teams />} />
        <Route path="players" element={<Players />} />
        <Route path="commissioner" element={<Commissioner />} />
      </Routes>
    </div>
  );
}

export default FIHL;
