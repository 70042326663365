import React from 'react';
import { Link } from 'react-router-dom';
import Banner from './Banner';

function PrivacyPolicyPage() {
  return (
    <div style={{ padding: '2em', fontFamily: 'Arial, sans-serif', lineHeight: '1.6' }}>
      <div style={{ maxWidth: '800px', margin: '0 auto', backgroundColor: '#f8f9fa', padding: '2em', borderRadius: '8px' }}>
        <h1 style={{ textAlign: 'center', color: '#333' }}>Privacy Policy for PuckSavvy.com</h1>
        <p><strong>Effective Date:</strong> January 21st, 2025</p>
        <p>At PuckSavvy.com, your privacy is our priority. This Privacy Policy outlines our practices and principles regarding your information. Simply put, we collect <strong>zero data</strong> from our users.</p>
        
        <h2>1. Information We Do Not Collect</h2>
        <ul>
          <li>Collect any personal information (e.g., names, email addresses, phone numbers).</li>
          <li>Track your browsing activity.</li>
          <li>Collect cookies or any other browser-related data.</li>
          <li>Use third-party analytics or tracking services.</li>
          <li>Retain or store any data related to your usage of PuckSavvy.com.</li>
        </ul>
        
        <h2>2. Third-Party Links</h2>
        <p>PuckSavvy.com may contain links to third-party websites. Please note that once you leave our website, we are not responsible for the privacy practices or content of those external sites. We encourage you to read the privacy policies of any third-party websites you visit.</p>
        
        <h2>3. Children's Privacy</h2>
        <p>PuckSavvy.com does not knowingly collect data from children under 13 years of age. Since we collect no data, this policy applies to all users regardless of age.</p>
        
        <h2>4. Policy Changes</h2>
        <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with the updated effective date. By continuing to use our website, you agree to this Privacy Policy and any future revisions.</p>
        
        <h2>5. Contact Us</h2>
        <p>If you have any questions or concerns about this Privacy Policy, feel free to contact us at https://discord.gg/xKB4ajeG7M</p>
        
        <p>Thank you for visiting PuckSavvy.com! Enjoy the data-free experience.</p>
      </div>
    </div>
  );
}

export default PrivacyPolicyPage;
