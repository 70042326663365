import React, { useState } from 'react';

function Commissioner() {
  const [enteredPassword, setEnteredPassword] = useState('');
  const [isAuthorized, setIsAuthorized] = useState(false);

  // Hard-coded password (not secure, just for demonstration)
  const CORRECT_PASSWORD = 'HBS2F6xI';

  // Handle unlocking the Commissioner Portal
  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (enteredPassword === CORRECT_PASSWORD) {
      setIsAuthorized(true);
    } else {
      alert('Incorrect password!');
    }
  };

  // Handle CSV file(s) selected
  const handleFileUpload = async (e) => {
    const files = e.target.files;
    // We'll filter out only CSV files
    const csvFiles = [...files].filter(file => file.name.endsWith('.csv'));

    if (csvFiles.length !== files.length) {
      alert('Some files are not CSV! Only CSV files are accepted.');
    }

    // Create a FormData object to send to the server
    const formData = new FormData();
    // "csvFiles" is the field name we will reference in the backend
    csvFiles.forEach(file => {
      formData.append('csvFiles', file);
    });

    try {
      const response = await fetch('/api/upload/fihl', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Upload request failed');
      }

      alert('Upload complete!');
    } catch (error) {
      console.error('Error uploading files:', error);
      alert('There was an error uploading your files');
    }
  };

  return (
    <div>
      <h2>Commissioner Portal</h2>

      {!isAuthorized ? (
        /* Render the password form */
        <form onSubmit={handlePasswordSubmit} style={{ margin: '2em 0' }}>
          <label>
            Enter Password:
            <input
              type="password"
              value={enteredPassword}
              onChange={(e) => setEnteredPassword(e.target.value)}
              style={{ marginLeft: '1em' }}
            />
          </label>
          <button type="submit" style={{ marginLeft: '1em' }}>Unlock</button>
        </form>
      ) : (
        /* If authorized, show file upload */
        <div>
          <p>Welcome, Commissioner!</p>
          <label>
            Upload CSV(s):
            <input
              type="file"
              accept=".csv"
              multiple
              onChange={handleFileUpload}
            />
          </label>
        </div>
      )}
    </div>
  );
}

export default Commissioner;
