import React from 'react';

function Players() {
  return (
    <div>
      <h2>Players</h2>
      {/* Display players data here */}
    </div>
  );
}

export default Players;