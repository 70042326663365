import React from 'react';
import Banner from './Banner';

function Layout({ children }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Banner />
      <main style={{ flex: 1 }}>{children}</main>
      <footer style={{ position: 'fixed', bottom: 0, width: '100%', backgroundColor: '#f1f1f1', textAlign: 'center', padding: '10px' }}>
        {/* Placeholder for your ad banner */}
        <div></div>
      </footer>
    </div>
  );
}

export default Layout;
