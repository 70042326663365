import React from 'react';

function Teams() {
  return (
    <div>
      <h2>Teams</h2>
      {/* Display teams data here */}
    </div>
  );
}

export default Teams;