// Home.js
import React from 'react';
import { Link } from 'react-router-dom';
import Banner from './Banner';
import buyHosting from './button_buy-hosting.png';
import joinDiscord from './button_join-discord.png';
import buyFHM from './button_buy-fhm.png';
import logo from './logo.png';
import discord from './discord.svg';
import './Home.css'; // Import the CSS file

function Home() {
  return (
    <div className="container">
      {/* Introductory Text */}
      <h1 className="title">
        Hosted FTP Servers for Franchise Hockey Manager
      </h1>
      <h2 className="subtitle">
        Hosted and Managed FTP Servers for FHM Online Leagues - Just $7.99/month!
      </h2>

      <div style={{ marginBottom: '2em' }}>
        <table style={{ borderCollapse: 'collapse', borderSpacing: '0', backgroundColor: '#292929', color: 'white', borderRadius: '10px', overflow: 'hidden' }}>
          <thead>
            <tr>
              <th style={{ padding: '1em', textAlign: 'center', fontSize: '1.5em', borderBottom: '2px solid white', borderRight: 'none' }}></th>
              <th style={{ padding: '1em', textAlign: 'left', fontSize: '1.5em', borderBottom: '2px solid white', borderLeft: 'none' }}>All members will receive:</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ padding: '1em', textAlign: 'center', borderBottom: '1px solid white', borderRight: 'none' }}>
                <img src={logo} alt="PuckSavvy FHM FTP Hosting" style={{ width: '150px', height: 'auto', borderRadius: '5px' }} />
              </td>
              <td style={{ padding: '1em', borderBottom: '1px solid white', borderLeft: 'none' }}>
                <ul style={{ fontSize: '1.5em', listStyleType: 'disc', padding: 0, margin: 0 }}>
                  <li>FTP Server Hosting for easy league and team file transfers</li>
                  <li>Unlimited FTP server tech support</li>
                  <li>Daily league and team file backups</li>
                  <li>Initial league setup and file preparation assistance</li>
                  <li>Limited ongoing league tech support and bug troubleshooting (max 5hrs/month)</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <h2 className="subtitle">
        <a href="https://discord.gg/xKB4ajeG7M" target="_blank" rel="noopener noreferrer">
        <img src={discord} alt="PuckSavvy FHM FTP Hosting" style={{ width: '300px', height: 'auto', borderRadius: '5px' }} />
      </a> <br />
        <i>Please join the server to get started</i>
      </h2>

      <div className="button-container">
        <a href="https://buy.stripe.com/cN2bK468T9Ym5yw4gh" target="_blank" rel="noopener noreferrer">
          <img src={buyHosting} alt="Buy Hosting" />
        </a>
        <a href="https://discord.gg/xKB4ajeG7M" target="_blank" rel="noopener noreferrer">
          <img src={joinDiscord} alt="Join Discord" />
        </a>
        <a href="https://store.steampowered.com/search/?term=FRANCHISE%20HOCKEY%20MANAGER&supportedlang=english&ndl=1" target="_blank" rel="noopener noreferrer">
          <img src={buyFHM} alt="Buy FHM" />
        </a>
      </div>

      {/* Privacy Policy Link */}
      <div style={{ marginTop: 'auto' }}>
        <Link to="/privacy-policy" style={{ color: 'blue', textDecoration: 'underline' }}>Privacy Policy</Link>
      </div>
    </div>
  );
}

export default Home;
