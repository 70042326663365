// App.js - Router Configuration
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './Layout';
import Home from './Home';
import Privacy from './privacy';
import FIHL from './leagues/fihl/fihl';
import './App.css';

function App() {
  return (
    <Router>
      <div style={{ backgroundColor: 'white', minHeight: '100vh' }}>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/leagues/fihl/*" element={<FIHL />} />
          </Routes>
        </Layout>
      </div>
    </Router>
  );
}

export default App;
